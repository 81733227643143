import axios from './bootstrap.js'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'
import VueAxios from 'vue-axios'
import Pagination from 'v-pagination-3'
import VueLoading from 'vue-loading-overlay'
import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue3 from 'bootstrap-vue-3'
import VSwitch from 'v-switch-case'
import Toaster from '@meforma/vue-toaster'
import { DatePicker } from 'v-calendar'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import './assets/scss/app.scss'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'v-calendar/dist/style.css'

const app = createApp(App)

app
  .component('pagination', Pagination)
  .component('VCalendar', DatePicker)

app.use(VueGtag, {
  property: {
    id: 'UA-123456-7'
  }
})

app.use(VueReCaptcha, {
  siteKey: '6LedxrsqAAAAAIi59g0Jk0SCwewHxrq0WTQrK2Ow'
})

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueLoading, {
    canCancel: false,
    color: '#666666',
    loader: 'dots',
    width: 60,
    height: 60,
    backgroundColor: '#A3A3A3',
    opacity: 0.5,
    zIndex: 999
  })
  .use(VueSweetalert2)
  .use(BootstrapVue3)
  .use(VSwitch)
  .use(Toaster)
  .mount('#app')
